.messages {
  padding: 5% 0;
  overflow: auto;
  flex: auto;
  margin-top: 1rem;
}

.messagesWrapper {
  display: flex;
  flex-direction: column;
  border: 1px black solid;
  height: 100px;
  overflow: scroll;
  margin-top: 1rem;
}
