@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
.messageBox {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 3px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #4770b7;
}

.backgroundLight {
  background: #f3f3f3;
}

.messages {
  padding: 5% 0;
  overflow: auto;
  flex: auto;
  margin-top: 1rem;
}

.messagesWrapper {
  display: flex;
  flex-direction: column;
  border: 1px black solid;
  height: 100px;
  overflow: scroll;
  margin-top: 1rem;
}

.messageBox {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 3px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #4770b7;
}

.backgroundLight {
  background: #f3f3f3;
}

.messages {
  padding: 5% 0;
  overflow: auto;
  flex: auto;
  margin-top: 1rem;
}

.messagesWrapper {
  display: flex;
  flex-direction: column;
  border: 1px black solid;
  height: 100px;
  overflow: scroll;
  margin-top: 1rem;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #f4f6f8;
}

